// ** MUI Imports
import { ThemeOptions } from '@mui/material'

const AppThemeOptions = (): ThemeOptions => {
  // ** Vars
  // https://colors.eva.design/
  // Light = 400
  // Main = 500
  // Dark = 600
  let primaryLightColor = '#ddd'
  let primaryMainColor = '#fff'
  let primaryDarkColor = '#ddd'

  let deepBackgroundColor = '#222'
  let darkenBackgroundColor = '#000'

  const defaultBgColor = () => {
    return darkenBackgroundColor
  }

  return {
    palette: {
      customColors: {
        darkBg: darkenBackgroundColor,
        bodyBg: darkenBackgroundColor
      },
      primary: {
        light: primaryLightColor,
        main: primaryMainColor,
        dark: primaryDarkColor,
        contrastText: '#000'
      },
      background: {
        paper: deepBackgroundColor,
        default: defaultBgColor()
      }
    }
  }
}

export default AppThemeOptions
