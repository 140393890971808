import React from 'react'
import { Box, Button, Container, Link, Typography } from '@mui/material'

const NotFoundScreen = () => {
  return (
    <Box
      component='main'
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexGrow: 1,
        minHeight: '100%'
      }}
    >
      <Container maxWidth='md'>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            mt: 20
          }}
        >
          <Typography align='center' color='textPrimary' variant='h6'>
            404
          </Typography>
          <Link href={'/'} underline={'none'}>
            <Button component='a' sx={{ mt: 3 }} variant='contained'>
              Riprova
            </Button>
          </Link>
        </Box>
      </Container>
    </Box>
  )
}

export default NotFoundScreen
