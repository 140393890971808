import React, { useEffect, useState, useRef } from 'react'
import useApi from '../../../hooks/useApi'
import { useNavigate, useParams } from 'react-router-dom'
import BlankCentered from '../../../components/BlankCentered'
import { Namespace } from '../../../types/namespace'
import { Chat } from '../../../types/chat'
import { Message } from '../../../types/message'
import toast from 'react-hot-toast'
import { TypeAnimation } from 'react-type-animation'
import { fmtAxiosMessage } from '../../../services/api/client'
import { ChevronLeft, Delete, MoreHoriz, Send } from '@mui/icons-material'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import {
  styled,
  Box,
  Container,
  Slide,
  CircularProgress,
  Typography,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  CardContent,
  TextField,
  Button,
  Drawer,
  InputAdornment,
  Avatar
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import moment from 'moment/moment'
import InfiniteScroll from 'react-infinite-scroll-component'
import useAuth from '../../../hooks/useAuth'

const ChatScreen = () => {
  const { id, namespaceId } = useParams()
  const api = useApi()
  const navigate = useNavigate()

  const [chat, setChat] = useState<Chat | undefined>(undefined)
  const [namespace, setNamespace] = useState<Namespace | undefined>(undefined)
  const [loading, setLoading] = useState(true)
  const [deleteDrawerOpen, setDeleteDrawerOpen] = useState(false)
  const [deleteing, setDeleteing] = useState(false)
  const [shouldAnimate, setShouldAnimate] = useState(false)

  const [messages, setMessages] = useState<Message[]>([])
  const [input, setInput] = useState<string>('')
  const [pendingInput, setPendingInput] = useState<string>('')
  const [next, setNext] = useState<string>('')
  const [adding, setAdding] = useState(false)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)
  const bottomRef = useRef(null)

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const fetchData = async () => {
    try {
      const chatResp = await api.chats.get(id!, namespaceId!)
      const namespaceResp = await api.namespaces.get(namespaceId!)
      setChat(chatResp)
      setNamespace(namespaceResp)
      fetchMessagesData(true)
      setLoading(false)
    } catch (err: any) {
      toast.error(fmtAxiosMessage(err))
      navigate(`/spazio/${namespaceId}`)
    }
  }

  const handleAddMessage = async () => {
    if (adding) {
      return
    }
    if (input.length == 0) {
      return
    }
    if (input.length > 1512) {
      toast.error('Il messaggio è troppo lungo')
      return
    }

    setAdding(true)
    setInput('')
    setPendingInput(input)
    setShouldAnimate(true)

    try {
      let history: Message[] = []
      if (messages) {
        for (const message of messages.slice(-20)) {
          if (message.output) {
            history.push(message)
          }
        }
      }

      await api.messages.create({
        namespace_id: namespaceId!,
        chat_id: id!,
        input: input,
        history,
      })
    } catch (err: any) {
      console.log(err)
    }

    await fetchMessagesData(true)
    setAdding(false)
    setPendingInput('')
  }

  const fetchMessagesData = async (firstPage?: boolean) => {
    const response = await api.messages.list({
      next: !firstPage ? next : '',
      namespace_id: namespaceId!,
      chat_id: id!
    })

    if (!firstPage) {
      setMessages(messages => [...response.rows!, ...messages])
    } else {
      setMessages(response.rows!)
    }

    setNext(response.next ?? '')
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (bottomRef) {
      // @ts-ignore
      bottomRef.current?.scrollIntoView()
    }

    if (messages && messages.length != 0) {
      const lastMessage = messages[messages.length - 1]

      if (!lastMessage.output && !lastMessage.retry) {
        setAdding(true)
        setShouldAnimate(true)

        setTimeout(async () => {
          await fetchMessagesData(true)
        }, 1000)
      } else {
        if (!pendingInput) {
          setAdding(false)
        }
      }
    }
  }, [messages, pendingInput])

  const onDeleteConfirm = async () => {
    setDeleteing(true)

    try {
      await api.chats.delete(id!)
      toast.success('La conversazione è stata eliminata con successo')
      navigate(`/spazio/${namespaceId}`)
    } catch (err: any) {
      toast.error(fmtAxiosMessage(err))
    }
  }

  return (
    <>
      {loading && (
        <BlankCentered className='layout-wrapper'>
          <Box className='app-content' sx={{ minHeight: '100vh', overflowX: 'hidden', position: 'relative' }}>
            <Box className='content-center'>
              <CircularProgress />
            </Box>
          </Box>
        </BlankCentered>
      )}
      {!loading && chat && namespace && (
        <>
          <Slide direction={'down'} in={true} mountOnEnter unmountOnExit>
            <Box sx={{ zIndex: 1000, position: 'fixed', width: '100%', backgroundColor: 'primary.contrastText' }}>
              <Container maxWidth='lg'>
                <Box
                  sx={{
                    py: 4,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}
                  >
                    <IconButton onClick={() => navigate(`/spazio/${namespaceId}`)} size='large'>
                      <ChevronLeft />
                    </IconButton>
                    <Box sx={{ ml: 3 }}>
                      <Typography variant='subtitle2'>
                        Conversazione iniziata {moment(chat.created_at).calendar()} sui
                      </Typography>
                      <Typography variant='subtitle1'>{namespace.name}</Typography>
                    </Box>
                  </Box>
                  <Tooltip title='Opzioni'>
                    <IconButton
                      onClick={handleMenuOpen}
                      size='large'
                      aria-controls={menuOpen ? 'chat-menu' : undefined}
                      aria-haspopup='true'
                      aria-expanded={menuOpen ? 'true' : undefined}
                    >
                      <MoreHoriz />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    anchorEl={anchorEl}
                    id='chat-menu'
                    open={menuOpen}
                    onClose={handleMenuClose}
                    onClick={handleMenuClose}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  >
                    <MenuItem onClick={() => setDeleteDrawerOpen(true)}>
                      <Delete color='error' sx={{ mr: 2 }} /> Elimina
                    </MenuItem>
                  </Menu>
                </Box>
              </Container>
              <Box
                sx={{
                  width: '100%',
                  borderBottom: theme => `2px solid ${theme.palette.divider}`
                }}
              />

              <Drawer
                anchor='right'
                open={deleteDrawerOpen}
                onClose={() => setDeleteDrawerOpen(false)}
                PaperProps={{ sx: { width: '400px', backgroundColor: 'primary.contrastText' } }}
              >
                <CardContent>
                  <Typography gutterBottom variant='h6'>
                    Elimina conversazioni
                  </Typography>
                  <Typography gutterBottom variant='body2'>
                    Tutte le historici della conversazione verranno eliminati.
                  </Typography>
                  <Box mt={4}></Box>
                  <Box mt={2}>
                    <Button
                      onClick={() => setDeleteDrawerOpen(false)}
                      color='primary'
                      fullWidth
                      size='large'
                      type='button'
                      variant='outlined'
                    >
                      Annulla
                    </Button>
                  </Box>
                  <Box mt={2}>
                    <LoadingButton
                      onClick={() => onDeleteConfirm()}
                      color='error'
                      loading={deleteing}
                      fullWidth
                      size='large'
                      type='button'
                      variant='contained'
                    >
                      Elimina
                    </LoadingButton>
                  </Box>
                </CardContent>
              </Drawer>
            </Box>
          </Slide>

          <Box sx={{ pt: 22, pb: 22 }}>
            {messages && messages.length != 0 && (
              // @ts-ignore
              <InfiniteScroll
                inverse={true}
                dataLength={messages.length}
                hasMore={!!next}
                next={fetchMessagesData}
                loader={null}
              >
                {messages.map((message, index) => (
                  <MessageItem
                    key={index}
                    data={message}
                    last={index === messages.length - 1 && shouldAnimate && !pendingInput}
                  />
                ))}
              </InfiniteScroll>
            )}
            {pendingInput && (
              // @ts-ignore
              <MessageItem
                data={{
                  input: pendingInput,
                  retry: false,
                  output: '',
                  id: '',
                  namespace_id: '',
                  chat_id: '',
                }}
              />
            )}
            <Box ref={bottomRef} />
          </Box>

          <Slide direction={'up'} in={true} mountOnEnter unmountOnExit>
            <Box
              sx={{
                zIndex: 1000,
                position: 'fixed',
                bottom: 0,
                width: '100%',
                backgroundColor: 'primary.contrastText'
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  borderTop: theme => `2px solid ${theme.palette.divider}`
                }}
              />
              <Container maxWidth='md'>
                <Box
                  sx={{
                    py: 4,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <Box sx={{ width: '100%' }}>
                    <TextField
                      fullWidth
                      autoFocus
                      placeholder='Invia un messaggio o fai una domanda'
                      onChange={e => setInput(e.target.value)}
                      value={input}
                      onKeyPress={e => {
                        if (e.key === 'Enter') {
                          handleAddMessage()
                          e.preventDefault()
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end' variant='filled'>
                            <IconButton
                              disabled={adding}
                              color='primary'
                              onClick={handleAddMessage}
                              edge='end'
                              sx={{ mr: 1 }}
                            >
                              <Send />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Box>
                </Box>
              </Container>
            </Box>
          </Slide>
        </>
      )}
    </>
  )
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  width: '60%',
  margin: '0 auto',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#ab68ff'
  }
}))

interface MessageItemProps {
  data: Message
  last?: boolean
}

const MessageItem = (props: MessageItemProps) => {
  const { userAttributes } = useAuth()

  const userInitials = (): string => {
    const name = userAttributes.get('name').split(' ')
    const firstName = name[0].charAt(0).toUpperCase()
    const lastName = name[name.length - 1].charAt(0).toUpperCase()
    return firstName + lastName
  }

  return (
    <Box>
      <Box sx={{ py: 6 }}>
        <Container maxWidth='md'>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'start' }}>
            <Avatar sx={{ width: 40, height: 40, mr: 6 }}>{userInitials()}</Avatar>
            <Typography sx={{ mt: 2 }}>{props.data.input}</Typography>
          </Box>
        </Container>
      </Box>
      <Box sx={{ py: 6, backgroundColor: 'action.disabledBackground' }}>
        <Container maxWidth='md'>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'start' }}>
            <Avatar
              sx={{ width: 40, height: 40, mr: 6, border: '2px solid #ab68ff' }}
              src={`/static/images/logo-avatar.png`}
            />
            {props.data.output && props.last && (
              <Typography sx={{ mt: 2, whiteSpace: 'pre-line'}}>
                <TypeAnimation cursor={false} key={props.data.output} speed={85} sequence={[props.data.output]} />
              </Typography>
            )}
            {props.data.output && !props.last && <Typography sx={{ mt: 2, whiteSpace: 'pre-line' }}>{props.data.output}</Typography>}
            {!props.data.retry && !props.data.output && (
              <Box sx={{ mt: 4, width: '100%', textAlign: 'center' }}>
                <BorderLinearProgress />
              </Box>
            )}
            {props.data.retry && !props.data.output && (
              <Typography sx={{ mt: 2 }} color={'error'}>
                Si è verificato un errore. Per favore riprova.
              </Typography>
            )}
          </Box>
        </Container>
      </Box>
    </Box>
  )
}

export default ChatScreen
