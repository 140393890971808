import { Amplify } from 'aws-amplify'
import { AwsConfig } from '../configs/aws'

export const configureAmplify = () => {
  Amplify.configure({
    Auth: {
      region: AwsConfig.region,
      userPoolId: AwsConfig.userPoolId,
      userPoolWebClientId: AwsConfig.userPoolWebClientId,
      mandatorySignIn: false,
      authenticationFlowType: 'USER_PASSWORD_AUTH'
    }
  })
}
