import { Client } from './client'
import { Namespace, NamespaceFormData, NamespaceListRequest, NamespaceRange } from '../../types/namespace'
import { Document, DocumentFormData, DocumentListRequest, DocumentRange } from '../../types/document'
import { Chat, ChatFormData, ChatListRequest, ChatRange } from '../../types/chat'
import { Message, MessageFormData, MessageListRequest, MessageRange } from '../../types/message'

export class ApiService extends Client {
  namespaces = {
    list: (params?: Partial<NamespaceListRequest>): Promise<Partial<NamespaceRange>> => {
      return this.request().append('/namespaces').params(params).method('get').build()
    },
    create: (data: NamespaceFormData): Promise<Namespace> => {
      return this.request().append('/namespaces').setData(data).method('post').build()
    },
    get: (id: string): Promise<Namespace> => {
      return this.request().append('/namespaces/').append(id).build()
    },
    delete: (id: string): Promise<void> => {
      return this.request().append('/namespaces/').append(id).method('delete').build()
    }
  }

  documents = {
    list: (params: Partial<DocumentListRequest>): Promise<Partial<DocumentRange>> => {
      return this.request().append('/documents').params(params).method('get').build()
    },
    create: (data: DocumentFormData): Promise<Document> => {
      return this.request().append('/documents').setData(data).method('post').build()
    }
  }

  chats = {
    list: (params: Partial<ChatListRequest>): Promise<Partial<ChatRange>> => {
      return this.request().append('/chats').params(params).method('get').build()
    },
    create: (data: ChatFormData): Promise<Chat> => {
      return this.request().append('/chats').setData(data).method('post').build()
    },
    get: (id: string, namespaceId: string): Promise<Chat> => {
      return this.request()
        .append('/chats/')
        .append(id)
        .params({
          namespace_id: namespaceId
        })
        .build()
    },
    delete: (id: string): Promise<void> => {
      return this.request().append('/chats/').append(id).method('delete').build()
    }
  }

  messages = {
    list: (params: Partial<MessageListRequest>): Promise<Partial<MessageRange>> => {
      return this.request().append('/messages').params(params).method('get').build()
    },
    create: (data: MessageFormData): Promise<Message> => {
      return this.request().append('/messages').setData(data).method('post').build()
    }
  }
}
