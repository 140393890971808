import React, { useEffect, useState } from 'react'
import { Auth } from 'aws-amplify'
import { CognitoUser } from '@aws-amplify/auth'
import { fromPromise } from 'rxjs/internal/observable/innerFrom'
import { catchError, finalize, map, Observable } from 'rxjs'

import { doOnSubscribe } from '../utils/rxjs'

export interface IAuthContextType {
  user: CognitoUser | null
  userAttributes: Map<string, any>
  signIn$: (input: { email: string; password: string }) => Observable<{ forcePasswordChange?: boolean }>
  signOut$: () => Observable<void>
  completeNewPassword$: (input: { password: string }) => Observable<void>
}

// Create a context object
export const AuthContext = React.createContext<IAuthContextType>({
  user: null
} as IAuthContextType)

interface IAuthProviderProps {
  children: React.ReactNode
}

// Create a provider for components to consume and subscribe to changes
export const AuthProvider = ({ children }: IAuthProviderProps) => {
  const [user, setUser] = useState<CognitoUser | null>(null)
  const [userAttributes, setUserAttributes] = useState<Map<string, any>>(new Map())
  const [isAuthenticating, setIsAuthenticating] = useState(true)

  useEffect(() => {
    fromPromise(Auth.currentAuthenticatedUser())
      .pipe(
        doOnSubscribe(() => setIsAuthenticating(true)),
        map(fetchedUser => {
          const cognitoUser: CognitoUser = fetchedUser as CognitoUser

          if (!cognitoUser) {
            throw Error('Current auth user cast error')
          }

          return cognitoUser
        }),
        finalize(() => setIsAuthenticating(false))
      )
      .subscribe({
        next: cognitoUser => {
          // @ts-ignore
          setUserAttributes(new Map(Object.entries(cognitoUser.attributes)))

          // set user information in state
          setUser(cognitoUser)
        },
        error: error => {
          console.error('currentAuthenticatedUse error', error)
        }
      })
  }, [])

  const signIn$ = ({
    email,
    password
  }: {
    email: string
    password: string
  }): Observable<{ forcePasswordChange?: boolean }> => {
    return fromPromise(Auth.signIn({ username: email, password })).pipe(
      map(signInResult => {
        if (!(signInResult instanceof CognitoUser)) {
          // this case should not arise as sign in result should always return CognitoUser
          throw Error('Critical Error')
        }

        // @ts-ignore
        if (signInResult.attributes) {
          // @ts-ignore
          setUserAttributes(new Map(Object.entries(signInResult.attributes)))
        }

        // set user information in state
        setUser(signInResult)

        // set force password change
        if (signInResult.challengeName == 'NEW_PASSWORD_REQUIRED') {
          return {
            forcePasswordChange: true
          }
        }

        return {}
      }),
      catchError(err => {
        throw err
      })
    )
  }

  const completeNewPassword$ = ({ password }: { password: string }): Observable<void> => {
    return fromPromise(Auth.completeNewPassword(user, password)).pipe(
      map(() => {}),
      catchError(err => {
        throw err
      })
    )
  }

  const signOut$ = () => {
    return fromPromise(Auth.signOut()).pipe(map(() => setUser(null)))
  }

  const value = {
    user,
    userAttributes,
    signIn$,
    signOut$,
    completeNewPassword$
  } satisfies IAuthContextType

  if (isAuthenticating) {
    return <></>
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export default AuthProvider
