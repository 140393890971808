import React, { useEffect, useState, SyntheticEvent } from 'react'
import useApi from '../../../hooks/useApi'
import { useNavigate, useParams } from 'react-router-dom'
import BlankCentered from '../../../components/BlankCentered'
import { Namespace } from '../../../types/namespace'
import { Document, DocumentFormData } from '../../../types/document'
import { Chat, ChatFormData } from '../../../types/chat'
import toast from 'react-hot-toast'
import { useDropzone } from 'react-dropzone'
import { fmtAxiosMessage } from '../../../services/api/client'
import { AddCommentOutlined, ChevronLeft, Check, Delete, MoreHoriz, ReportProblem } from '@mui/icons-material'
import {
  Box,
  Container,
  CircularProgress,
  Typography,
  Slide,
  Tooltip,
  IconButton,
  Alert,
  Grid,
  Menu,
  MenuItem,
  CardContent,
  Button,
  Drawer,
  Grow,
  Card,
  CardActionArea,
  FormControl,
  TextField
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { LoadingButton } from '@mui/lab'
import moment from 'moment/moment'
import { object, string } from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import DropzoneWrapper from '../../../styles/react-dropzone'

export function convertBase64(file: any) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)
    fileReader.onload = () => {
      resolve(fileReader.result)
    }
    fileReader.onerror = error => {
      reject(error)
    }
  })
}

const NamespaceScreen = () => {
  const { id } = useParams()
  const api = useApi()
  const navigate = useNavigate()

  const [namespace, setNamespace] = useState<Namespace | undefined>(undefined)
  const [documents, setDocuments] = useState<Document[]>([])
  const [chats, setChats] = useState<Chat[]>([])
  const [loading, setLoading] = useState(true)
  const [deleteDrawerOpen, setDeleteDrawerOpen] = useState(false)
  const [deleteing, setDeleteing] = useState(false)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const fetchData = async () => {
    try {
      const namespaceResp = await api.namespaces.get(id!)
      const documentsResp = await api.documents.list({ namespace_id: id! })
      const chatsResp = await api.chats.list({ namespace_id: id! })
      setNamespace(namespaceResp)
      setDocuments(documentsResp.rows!)
      setChats(chatsResp.rows!)
      setLoading(false)

      if (documentsResp && documentsResp.rows)
        for (const document of documentsResp.rows) {
          if (document.is_indexed && !document.indexing_failed) {
            setChatAvailable(true)
          }
        }
    } catch (err: any) {
      toast.error(fmtAxiosMessage(err))
      navigate('/')
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const onDeleteConfirm = async () => {
    setDeleteing(true)

    try {
      await api.namespaces.delete(id!)
      toast.success('Il spazio è stato eliminato con successo')
      navigate('/')
    } catch (err: any) {
      toast.error(fmtAxiosMessage(err))
    }
  }

  const [chatAvailable, setChatAvailable] = useState(false)
  const [addingChat, setAddingChat] = useState(false)

  const handleAddChat = async () => {
    if (addingChat) {
      return
    }

    setAddingChat(true)

    try {
      const chat = await api.chats.create({
        namespace_id: id!
      } as ChatFormData)
      navigate(`/spazio/${id}/chat/${chat.id}`)
    } catch (err: any) {
      toast.error(fmtAxiosMessage(err))
    }
  }

  const [addDrawerOpen, setAddDrawerOpen] = useState(false)
  const [adding, setAdding] = useState(false)
  const [fileDocument, setFileDocument] = useState<File[]>([])

  const addInitialValues: DocumentFormData = {
    namespace_id: id!,
    name: '',
    base64: ''
  }

  const addValidationSchema = object().shape({
    name: string().required(`Il nome è obbligatorio`)
  })

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    maxSize: 6000000,
    accept: {
      'application/pdf': ['.pdf']
    },
    onDrop: async (acceptedFiles: File[]) => {
      setFileDocument(acceptedFiles.map((file: File) => Object.assign(file)))
    },
    onDropRejected: () => {
      toast.error('È possibile utilizzare file fino a 6 MB.', {
        duration: 2000
      })
    }
  })

  const handleLinkClick = (event: SyntheticEvent) => {
    event.preventDefault()
  }

  const uploadedDocument = fileDocument.map((file: any) => (
    <Box
      key={file.name}
      sx={{
        display: 'flex',
        flexDirection: ['column', 'column', 'row'],
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: ['center', 'center', 'inherit']
        }}
      >
        <Typography variant='subtitle1'>{file.name}</Typography>
        <Typography variant='body2' color='success.main'>
          Documento PDF caricato.
        </Typography>
      </Box>
    </Box>
  ))

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<DocumentFormData>({
    defaultValues: addInitialValues,
    resolver: yupResolver(addValidationSchema)
  })

  const onSubmitAdd = handleSubmit(async data => {
    setAdding(true)

    try {
      var uploadedFile: File | undefined

      for (const file of fileDocument) {
        uploadedFile = file
        break
      }

      if (!uploadedFile) {
        toast.error('È necessario inviare il documento PDF')
        setAdding(false)

        return
      }

      data.base64 = (await convertBase64(uploadedFile)) as string

      await api.documents.create(data)
      await fetchData()
      toast.success('Il documento è stato creato con successo')
      setFileDocument([])
      setAddDrawerOpen(false)
      reset()
    } catch (err: any) {
      toast.error(fmtAxiosMessage(err))
    }

    setAdding(false)
  })

  useEffect(() => {
    const interval = setInterval(async () => {
      let pending = false
      for (const document of documents) {
        if (document.is_indexed && !document.indexing_failed) {
          setChatAvailable(true)
        }
        if (!document.is_indexed && !document.indexing_failed) {
          pending = true
        }
      }

      if (!pending) {
        return
      }

      const documentsResp = await api.documents.list({ namespace_id: id! })
      setDocuments(documentsResp.rows!)
    }, 1000)

    return () => clearInterval(interval)
  }, [documents])

  return (
    <>
      {loading && (
        <BlankCentered className='layout-wrapper'>
          <Box className='app-content' sx={{ minHeight: '100vh', overflowX: 'hidden', position: 'relative' }}>
            <Box className='content-center'>
              <CircularProgress />
            </Box>
          </Box>
        </BlankCentered>
      )}
      {!loading && namespace && (
        <Box sx={{ minHeight: '100vh', overflowX: 'hidden', position: 'relative' }}>
          <Slide direction={'down'} in={true} mountOnEnter unmountOnExit>
            <Box sx={{ zIndex: 1000, position: 'fixed', width: '100%', backgroundColor: 'primary.contrastText' }}>
              <Container maxWidth='lg'>
                <Box
                  sx={{
                    py: 4,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}
                  >
                    <IconButton onClick={() => navigate('/')} size='large'>
                      <ChevronLeft />
                    </IconButton>
                    <Typography variant='h5' sx={{ ml: 3 }}>
                      {namespace.name}
                    </Typography>
                  </Box>
                  <Tooltip title='Opzioni'>
                    <IconButton
                      onClick={handleMenuOpen}
                      size='large'
                      aria-controls={menuOpen ? 'namespace-menu' : undefined}
                      aria-haspopup='true'
                      aria-expanded={menuOpen ? 'true' : undefined}
                    >
                      <MoreHoriz />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    anchorEl={anchorEl}
                    id='namespace-menu'
                    open={menuOpen}
                    onClose={handleMenuClose}
                    onClick={handleMenuClose}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  >
                    <MenuItem onClick={() => setDeleteDrawerOpen(true)}>
                      <Delete color='error' sx={{ mr: 2 }} /> Elimina
                    </MenuItem>
                  </Menu>
                </Box>
              </Container>
              <Box
                sx={{
                  width: '100%',
                  borderBottom: theme => `2px solid ${theme.palette.divider}`
                }}
              />
            </Box>
          </Slide>
          <Container maxWidth='lg' sx={{ pt: 25 }}>
            <Box
              sx={{
                py: 4,
                mb: 5
              }}
            >
              <Typography variant='h6' gutterBottom>
                Conversazioni
              </Typography>
              {!chatAvailable && (
                <Alert color='warning' icon={<ReportProblem />}>
                  Aggiungi documenti a spazio per avviare conversazioni.
                </Alert>
              )}
              {chatAvailable && (
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={4} lg={4}>
                    <Grow in={true}>
                      <Card
                        sx={{
                          height: '100%',
                          backgroundColor: addingChat ? '' : 'primary.contrastText',
                          border: theme => `1px solid ${theme.palette.divider}`
                        }}
                      >
                        <CardActionArea onClick={handleAddChat} sx={{ height: '100%' }}>
                          <CardContent>
                            <Grid container sx={{ textAlign: 'center' }}>
                              <Grid item xs={12}>
                                {addingChat && <CircularProgress color='primary' size={'2rem'} />}
                                {!addingChat && <AddCommentOutlined fontSize={'large'} />}
                              </Grid>
                              <Grid item xs={12}>
                                <Typography variant='subtitle1'>Nuova conversazioni</Typography>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grow>
                  </Grid>
                  {chats.map((chat, index) => (
                    <ChatItem key={index} data={chat} />
                  ))}
                </Grid>
              )}
            </Box>

            <Box
              sx={{
                py: 4,
                mb: 5
              }}
            >
              <Typography variant='h6' gutterBottom>
                Documenti
              </Typography>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={4} lg={4}>
                  <Grow in={true}>
                    <Card
                      sx={{
                        height: '100%',
                        backgroundColor: 'primary.contrastText',
                        border: theme => `1px solid ${theme.palette.divider}`
                      }}
                    >
                      <CardActionArea onClick={() => setAddDrawerOpen(true)} sx={{ height: '100%' }}>
                        <CardContent>
                          <Grid container sx={{ textAlign: 'center' }}>
                            <Grid item xs={12}>
                              <AddIcon fontSize={'large'} />
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant='subtitle1'>Aggiungi documento</Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grow>
                </Grid>
                {documents.map((document, index) => (
                  <DocumentItem key={index} data={document} />
                ))}
              </Grid>

              <Drawer
                anchor='right'
                open={addDrawerOpen}
                onClose={() => setAddDrawerOpen(false)}
                PaperProps={{ sx: { width: '400px', backgroundColor: 'primary.contrastText' } }}
              >
                <CardContent>
                  <Typography gutterBottom variant='h6'>
                    Aggiungi documento
                  </Typography>
                  <form onSubmit={onSubmitAdd}>
                    <FormControl fullWidth sx={{ mt: 4, mb: 4 }}>
                      <Controller
                        name='name'
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <TextField
                            error={'name' in errors}
                            fullWidth
                            helperText={errors.name?.message}
                            label='Nome'
                            name='name'
                            onBlur={onBlur}
                            onChange={onChange}
                            type='text'
                            value={value}
                            variant='outlined'
                          />
                        )}
                      />
                    </FormControl>

                    <DropzoneWrapper>
                      <Box {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        {fileDocument && fileDocument.length ? (
                          uploadedDocument
                        ) : (
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: ['column', 'column', 'row'],
                              alignItems: 'center'
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                textAlign: ['center', 'center', 'inherit']
                              }}
                            >
                              <Typography variant='subtitle1'>Carica il PDF</Typography>
                              <Typography variant='body2' color='textSecondary'>
                                Sono consentiti solo file .pdf fino a 6 MB.
                              </Typography>
                              <Typography variant='body2' color='textSecondary'>
                                Trascina il file qui o{' '}
                                <Button variant={'text'} href='/' onClick={handleLinkClick}>
                                  navigare
                                </Button>{' '}
                                nei tuoi file.
                              </Typography>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </DropzoneWrapper>

                    <Box mt={4}>
                      <LoadingButton
                        color='primary'
                        loading={adding}
                        fullWidth
                        size='large'
                        type='submit'
                        variant='contained'
                      >
                        Invia
                      </LoadingButton>
                    </Box>
                  </form>
                </CardContent>
              </Drawer>
            </Box>
          </Container>

          <Drawer
            anchor='right'
            open={deleteDrawerOpen}
            onClose={() => setDeleteDrawerOpen(false)}
            PaperProps={{ sx: { width: '400px', backgroundColor: 'primary.contrastText' } }}
          >
            <CardContent>
              <Typography gutterBottom variant='h6'>
                Elimina spazio
              </Typography>
              <Typography gutterBottom variant='body2'>
                Tutte le conversazioni e i documenti di spazio verranno eliminati.
              </Typography>
              <Box mt={4}></Box>
              <Box mt={2}>
                <Button
                  onClick={() => setDeleteDrawerOpen(false)}
                  color='primary'
                  fullWidth
                  size='large'
                  type='button'
                  variant='outlined'
                >
                  Annulla
                </Button>
              </Box>
              <Box mt={2}>
                <LoadingButton
                  onClick={() => onDeleteConfirm()}
                  color='error'
                  loading={deleteing}
                  fullWidth
                  size='large'
                  type='button'
                  variant='contained'
                >
                  Elimina
                </LoadingButton>
              </Box>
            </CardContent>
          </Drawer>
        </Box>
      )}
    </>
  )
}

interface DocumentItemProps {
  data: Document
}

const DocumentItem = (props: DocumentItemProps) => {
  return (
    <Grid item xs={12} sm={4} lg={4}>
      <Grow in={true}>
        <Card sx={{ height: '100%' }}>
          <CardContent>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant='subtitle1'>{props.data.name}</Typography>
              </Grid>
              <Grid item xs={6} sx={{ textAlign: 'right' }}>
                <Typography variant='caption'>{moment(props.data.created_at).fromNow()}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    pt: 5,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  {!props.data.is_indexed && !props.data.indexing_failed && (
                    <>
                      <Typography variant='overline'>Indexing</Typography>
                      <CircularProgress color='warning' size={'1rem'} />
                    </>
                  )}
                  {props.data.is_indexed && !props.data.indexing_failed && (
                    <>
                      <Typography variant='overline'>Pronto</Typography>
                      <Check color='success' />
                    </>
                  )}
                  {props.data.indexing_failed && (
                    <>
                      <Typography variant='overline'>Con errore</Typography>
                      <ReportProblem color='error' />
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grow>
    </Grid>
  )
}

interface ChatItemProps {
  data: Chat
}

const ChatItem = (props: ChatItemProps) => {
  const navigate = useNavigate()

  return (
    <Grid item xs={12} sm={4} lg={4}>
      <Grow in={true}>
        <Card sx={{ height: '100%', minHeight: '110px' }}>
          <CardActionArea
            onClick={() => navigate(`/spazio/${props.data.namespace_id}/chat/${props.data.id}`)}
            sx={{ height: '100%' }}
          >
            <CardContent>
              <Typography variant='subtitle2'>{moment(props.data.created_at).calendar()}</Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grow>
    </Grid>
  )
}

export default NamespaceScreen
