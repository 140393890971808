import React, { useState } from 'react'
import { Box, Container, TextField, Typography, Slide, FormControl } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { Controller, useForm } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import { object, string, ref } from 'yup'
import { finalize } from 'rxjs'
import toast from 'react-hot-toast'
import BlankCentered from '../../../components/BlankCentered'
import useAuth from '../../../hooks/useAuth'
import { CompleteNewPasswordFormData } from '../../../types/auth'
import { doOnSubscribe } from '../../../utils/rxjs'
import { useNavigate } from 'react-router-dom'

const CompleteNewPasswordScreen = () => {
  const navigate = useNavigate()
  const { completeNewPassword$ } = useAuth()

  const [loading, setLoading] = useState(false)

  const initialValues: CompleteNewPasswordFormData = {
    password: '',
    passwordConfirm: ''
  }

  const validationSchema = object().shape({
    password: string().min(6, `La password deve contenere almeno 6 caratteri`).required('La password è obbligatoria'),
    passwordConfirm: string()
      .oneOf([ref('password')], 'Le password devono coincidere')
      .required('La conferma della password è obbligatoria')
  })

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<CompleteNewPasswordFormData>({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema)
  })

  const onSubmit = handleSubmit(data => {
    completeNewPassword$({
      password: data.password
    })
      .pipe(
        doOnSubscribe(() => setLoading(true)),
        finalize(() => setLoading(false))
      )
      .subscribe({
        next: () => {
          window.location.href = '/'
        },
        error: err => {
          toast.error('Errore di sistema, riprova più tardi')
          navigate('/')
        }
      })
  })

  return (
    <BlankCentered className='layout-wrapper'>
      <Box className='app-content' sx={{ minHeight: '100vh', overflowX: 'hidden', position: 'relative' }}>
        <Box className='content-center'>
          <Slide direction={'down'} in={true} mountOnEnter unmountOnExit>
            <Container maxWidth='xs'>
              <form onSubmit={onSubmit}>
                <Box
                  sx={{
                    mb: 12,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <img alt={'Logo'} width={100} height={100} src={`/static/images/icon-darkmode.svg`} />
                </Box>

                <Box sx={{ my: 4 }}>
                  <Typography variant='subtitle1' align='center'>
                    Cambia la tua password.
                  </Typography>
                </Box>

                <FormControl fullWidth sx={{ mb: 4 }}>
                  <Controller
                    name='password'
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextField
                        error={'password' in errors}
                        fullWidth
                        helperText={errors.password?.message}
                        label='Nuova password'
                        name='password'
                        onBlur={onBlur}
                        onChange={onChange}
                        type='password'
                        value={value}
                        variant='outlined'
                      />
                    )}
                  />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 4 }}>
                  <Controller
                    name='passwordConfirm'
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextField
                        error={'passwordConfirm' in errors}
                        fullWidth
                        helperText={errors.passwordConfirm?.message}
                        label='Conferma nuova password'
                        name='passwordConfirm'
                        onBlur={onBlur}
                        onChange={onChange}
                        type='password'
                        value={value}
                        variant='outlined'
                      />
                    )}
                  />
                </FormControl>

                <Box mt={2}>
                  <LoadingButton
                    color='primary'
                    loading={loading}
                    fullWidth
                    size='large'
                    type='submit'
                    variant='contained'
                  >
                    Aggiorna password
                  </LoadingButton>
                </Box>
              </form>
            </Container>
          </Slide>
        </Box>
      </Box>
    </BlankCentered>
  )
}

export default CompleteNewPasswordScreen
