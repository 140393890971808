import React, { useState } from 'react'
import { Box, Container, TextField, Typography, Fade, FormControl } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string } from 'yup'
import { finalize } from 'rxjs'
import toast from 'react-hot-toast'
import BlankCentered from '../../../components/BlankCentered'
import useAuth from '../../../hooks/useAuth'
import { doOnSubscribe } from '../../../utils/rxjs'
import { SignInFormData } from '../../../types/auth'

const SignInScreen = () => {
  const navigate = useNavigate()
  const { signIn$ } = useAuth()

  const [loading, setLoading] = useState(false)

  const initialValues: SignInFormData = {
    email: '',
    password: ''
  }

  const validationSchema = object().shape({
    email: string().email('Deve essere un indirizzo email valido').required(`L'indirizzo email è obbligatorio`),
    password: string().min(6, `La password deve contenere almeno 6 caratteri`).required('La password è obbligatoria')
  })

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<SignInFormData>({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema)
  })

  const onSubmit = handleSubmit(data => {
    signIn$({
      email: data.email,
      password: data.password
    })
      .pipe(
        doOnSubscribe(() => setLoading(true)),
        finalize(() => setLoading(false))
      )
      .subscribe({
        next: result => {
          console.log(result)
          if (result.forcePasswordChange) {
            navigate('/complete-new-password')

            return
          }

          navigate('/')
        },
        error: err => {
          console.log(err)
          toast.error('La password inserita è incorretta. Per favore, riprova')
        }
      })
  })

  return (
    <BlankCentered className='layout-wrapper'>
      <Box className='app-content' sx={{ minHeight: '100vh', overflowX: 'hidden', position: 'relative' }}>
        <Box className='content-center'>
          <Fade in={true} mountOnEnter unmountOnExit>
            <Container maxWidth='xs'>
              <form onSubmit={onSubmit}>
                <Box
                  sx={{
                    mb: 12,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <img alt={'Logo'} width={100} height={100} src={`/static/images/icon-darkmode.svg`} />
                </Box>

                <Box sx={{ my: 4 }}>
                  <Typography variant='subtitle1' align='center'>
                    Benvenuto, inserisci le tue credenziali per continuare.
                  </Typography>
                </Box>
                <FormControl fullWidth sx={{ mb: 4 }}>
                  <Controller
                    name='email'
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextField
                        error={'email' in errors}
                        fullWidth
                        helperText={errors.email?.message}
                        label='Indirizzo email'
                        name='email'
                        onBlur={onBlur}
                        onChange={onChange}
                        type='email'
                        value={value}
                        variant='outlined'
                      />
                    )}
                  />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 4 }}>
                  <Controller
                    name='password'
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextField
                        error={'password' in errors}
                        fullWidth
                        helperText={errors.password?.message}
                        label='Password'
                        name='password'
                        onBlur={onBlur}
                        onChange={onChange}
                        type='password'
                        value={value}
                        variant='outlined'
                      />
                    )}
                  />
                </FormControl>

                <Box mt={2}>
                  <LoadingButton
                    color='primary'
                    loading={loading}
                    fullWidth
                    size='large'
                    type='submit'
                    variant='contained'
                  >
                    Accedi
                  </LoadingButton>
                </Box>
              </form>
            </Container>
          </Fade>
        </Box>
      </Box>
    </BlankCentered>
  )
}

export default SignInScreen
