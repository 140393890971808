// ** MUI Theme Provider
import { deepmerge } from '@mui/utils'
import { ThemeOptions } from '@mui/material'

// ** Manager Theme Options
import AppThemeOptions from './AppThemeOptions'

// ** Theme Override Imports
import palette from './palette'
import spacing from './spacing'
import shadows from './shadows'
import breakpoints from './breakpoints'

const themeOptions = (): ThemeOptions => {
  // ** Create New object before removing manager component overrides and typography objects from managerThemeOptions
  const managerThemeConfig: any = Object.assign({}, AppThemeOptions())

  const managerFontFamily = managerThemeConfig.typography?.fontFamily

  // ** Remove component overrides and typography objects from managerThemeOptions
  delete managerThemeConfig.components
  delete managerThemeConfig.typography

  const mergedThemeConfig = deepmerge(
    {
      palette: palette('dark'),
      typography: {
        fontFamily:
          managerFontFamily ||
          [
            'Signika',
            'sans-serif',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
          ].join(',')
      },
      shadows: shadows('dark'),
      ...spacing,
      breakpoints: breakpoints(),
      shape: {
        borderRadius: 6
      },
      mixins: {
        toolbar: {
          minHeight: 64
        }
      }
    },
    managerThemeConfig
  )

  return deepmerge(mergedThemeConfig, {
    palette: {
      primary: {
        ...mergedThemeConfig.palette['primary']
      }
    }
  })
}

export default themeOptions
