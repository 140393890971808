import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import NotFoundScreen from './NotFound'
import DashboardScreen from './Dashboard'
import NamespaceScreen from './Namespace'
import ChatScreen from './Chat'

const ApplicationModule = () => {
  return (
    <Routes>
      <Route path='dashboard/*' element={<DashboardScreen />} />
      <Route path='/' element={<Navigate to='dashboard' replace />} />
      <Route path='spazio/:id' element={<NamespaceScreen />} />
      <Route path='spazio/:namespaceId/chat/:id' element={<ChatScreen />} />

      <Route path='*' element={<NotFoundScreen />} />
    </Routes>
  )
}

export default ApplicationModule
