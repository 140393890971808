import React, { useEffect, useState } from 'react'
import useApi from '../../../hooks/useApi'
import useAuth from '../../../hooks/useAuth'
import AddIcon from '@mui/icons-material/Add'
import moment from 'moment'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Namespace, NamespaceFormData } from '../../../types/namespace'
import {
  Box,
  Container,
  Tooltip,
  IconButton,
  Drawer,
  Slide,
  Grow,
  Fab,
  Fade,
  Avatar,
  Typography,
  Divider,
  useMediaQuery,
  Grid,
  Card,
  CardActionArea,
  CardContent,
  Menu,
  MenuItem,
  FormControl,
  TextField
} from '@mui/material'
import { object, string } from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import toast from 'react-hot-toast'
import { LoadingButton } from '@mui/lab'
import { fmtAxiosMessage } from '../../../services/api/client'
import { useNavigate } from 'react-router-dom'

const DashboardScreen = () => {
  const api = useApi()
  const { signOut$, userAttributes } = useAuth()
  const navigate = useNavigate()

  const isMobile = useMediaQuery('(max-width:600px)')
  const [namespaces, setNamespaces] = useState<Namespace[]>([])
  const [next, setNext] = useState<string>('')

  const userInitials = (): string => {
    const name = userAttributes.get('name').split(' ')
    const firstName = name[0].charAt(0).toUpperCase()
    const lastName = name[name.length - 1].charAt(0).toUpperCase()
    return firstName + lastName
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleSignOut = () => {
    signOut$().subscribe()
    setAnchorEl(null)
  }

  const [addDrawerOpen, setAddDrawerOpen] = useState(false)
  const [adding, setAdding] = useState(false)

  const addInitialValues: NamespaceFormData = {
    name: ''
  }

  const addValidationSchema = object().shape({
    name: string().required(`Il nome è obbligatorio`)
  })

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<NamespaceFormData>({
    defaultValues: addInitialValues,
    resolver: yupResolver(addValidationSchema)
  })

  const onSubmitAdd = handleSubmit(async data => {
    setAdding(true)

    try {
      await api.namespaces.create(data)
      await fetchData(true)
      toast.success('Il spazio è stato creato con successo')
      setAddDrawerOpen(false)
      reset()
    } catch (err: any) {
      toast.error(fmtAxiosMessage(err))
    }

    setAdding(false)
  })

  const fetchData = async (firstPage?: boolean) => {
    const response = await api.namespaces.list({
      next: !firstPage ? next : ''
    })

    if (!firstPage) {
      setNamespaces(namespaces => [...namespaces, ...response.rows!])
    } else {
      setNamespaces(response.rows!)
    }

    setNext(response.next ?? '')
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <Box sx={{ minHeight: '100vh', overflowX: 'hidden', position: 'relative' }}>
      <Box sx={{ zIndex: 1200, position: 'fixed', width: '100%' }}>
        <Container maxWidth='md'>
          <Slide direction={'down'} in={true} mountOnEnter unmountOnExit>
            <Box
              sx={{
                py: 2,
                px: 4,
                pt: 4,
                mt: -3,
                display: 'flex',
                borderRadius: 1,
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                border: theme => `2px solid ${theme.palette.divider}`,
                backgroundColor: 'primary.contrastText'
              }}
            >
              <img alt={'Logo'} width={25} height={25} src={`/static/images/icon-darkmode.svg`} />
              {!isMobile && (
                <Typography variant='subtitle2' align='center'>
                  {userAttributes.get('custom:customer_name')}
                </Typography>
              )}
              <Tooltip title='Il tuo account'>
                <IconButton
                  onClick={handleMenuOpen}
                  size='small'
                  aria-controls={menuOpen ? 'account-menu' : undefined}
                  aria-haspopup='true'
                  aria-expanded={menuOpen ? 'true' : undefined}
                >
                  <Avatar sx={{ width: 40, height: 40 }}>{userInitials()}</Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={anchorEl}
                id='account-menu'
                open={menuOpen}
                onClose={handleMenuClose}
                onClick={handleMenuClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem>{userAttributes?.get('name')}</MenuItem>
                <Divider />
                <MenuItem onClick={handleSignOut}>Esci</MenuItem>
              </Menu>
            </Box>
          </Slide>
        </Container>
      </Box>

      <Container maxWidth='md' sx={{ pt: 18 }}>
        <Fade in={true} timeout={1000} mountOnEnter unmountOnExit>
          <Box
            sx={{
              py: 4,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            {!isMobile && (
              <Typography variant='body1'>
                Ciao, gestisci i tuoi spazi e inizia a interagire con i tuoi documenti.
              </Typography>
            )}
            <Fab
              onClick={() => setAddDrawerOpen(true)}
              variant='extended'
              size='medium'
              sx={{ width: isMobile ? '100%' : 'auto' }}
            >
              <AddIcon sx={{ ml: 1 }} />
              Nuovo spazio
            </Fab>

            <Drawer
              anchor='right'
              open={addDrawerOpen}
              onClose={() => setAddDrawerOpen(false)}
              PaperProps={{ sx: { width: '400px', backgroundColor: 'primary.contrastText' } }}
            >
              <CardContent>
                <Typography gutterBottom variant='h6'>
                  Nuovo spazio
                </Typography>
                <form onSubmit={onSubmitAdd}>
                  <FormControl fullWidth sx={{ mt: 4, mb: 4 }}>
                    <Controller
                      name='name'
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <TextField
                          error={'name' in errors}
                          fullWidth
                          helperText={errors.name?.message}
                          label='Nome'
                          name='name'
                          onBlur={onBlur}
                          onChange={onChange}
                          type='text'
                          value={value}
                          variant='outlined'
                        />
                      )}
                    />
                  </FormControl>

                  <Box mt={2}>
                    <LoadingButton
                      color='primary'
                      loading={adding}
                      fullWidth
                      size='large'
                      type='submit'
                      variant='contained'
                    >
                      Invia
                    </LoadingButton>
                  </Box>
                </form>
              </CardContent>
            </Drawer>
          </Box>
        </Fade>

        {namespaces && namespaces.length != 0 && (
          // @ts-ignore
          <InfiniteScroll dataLength={namespaces.length} hasMore={!!next} next={fetchData} loader={null}>
            <Grid container spacing={4}>
              {namespaces.map((namespace, index) => (
                <NamespaceItem key={index} data={namespace} onClick={() => navigate(`/spazio/${namespace.id}`)} />
              ))}
            </Grid>
          </InfiniteScroll>
        )}

        <Box sx={{ mb: 5 }} />
      </Container>
    </Box>
  )
}

interface NamespaceItemProps {
  data: Namespace
  onClick: () => void
}

const NamespaceItem = (props: NamespaceItemProps) => {
  return (
    <Grid item xs={12} sm={6} lg={6}>
      <Grow in={true}>
        <Card sx={{ height: '100%' }}>
          <CardActionArea onClick={props.onClick} sx={{ height: '100%' }}>
            <CardContent>
              <Grid container style={{ display: 'flex', alignItems: 'center' }}>
                <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }}>
                  <Typography variant='subtitle1' component='div'>
                    {props.data.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} order={{ xs: 2, md: 1 }}>
                  <Typography variant='caption' component='div' sx={{ textAlign: 'right' }}>
                    {moment(props.data.created_at).fromNow()}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grow>
    </Grid>
  )
}

export default DashboardScreen
