import React from 'react'
import ReactDOM from 'react-dom/client'

import { BrowserRouter } from 'react-router-dom'
import AuthProvider from './context/AuthContext'
import Modules from './modules'
import { Toaster } from 'react-hot-toast'
import ReactHotToast from './styles/react-hot-toast'

import ThemeComponent from './theme/ThemeComponent'

import { configureAmplify } from './lib/amplify'

import 'moment-duration-format'
import 'moment/locale/it'

configureAmplify()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <ThemeComponent>
    <BrowserRouter>
      <AuthProvider>
        <Modules />
      </AuthProvider>
    </BrowserRouter>
    <ReactHotToast>
      <Toaster position={'top-center'} toastOptions={{ className: 'react-hot-toast', duration: 5000 }} />
    </ReactHotToast>
  </ThemeComponent>
)
