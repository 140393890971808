import React from 'react'
import AuthModule from './Auth'
import ApplicationModule from './Application'
import useAuth from '../hooks/useAuth'

const Modules = () => {
  const { user } = useAuth()

  return user && !user.challengeName ? <ApplicationModule /> : <AuthModule />
}

export default Modules
